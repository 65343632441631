import { PlanType } from '../InternalPatientSignup/types';
import { FamilyDiscounts } from '../MembershipPlansManage/types';

export const getGroupDiscounts = (data?: FamilyDiscounts) => ({
  two: data?.family_discount_two ?? undefined,
  three: data?.family_discount_three ?? undefined,
  four: data?.family_discount_four ?? undefined,
});

export const getGroupDiscount = ({
  groupDiscounts: { two, three, four },
  numberOfMembers,
}: {
  groupDiscounts: { two?: number; three?: number; four?: number };
  numberOfMembers: number;
}): number => {
  if (numberOfMembers === 2 && !!two) {
    return two;
  } else if (numberOfMembers === 3) {
    return three || two || 0;
  } else if (numberOfMembers > 3) {
    return four || three || two || 0;
  }

  return 0;
};

export const getPlanTypeFormatMessageProps = (
  id: string,
  planType?: PlanType,
) => {
  return {
    id: planType ? `${id}.${planType}` : `${id}.uninsured`,
  };
};
