import { useGetSettingsUsers } from 'new/modules/OfficeSettings/hooks';
import useActiveOffice from './useActiveOffice';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { TENANT } from 'new/tenants';
const cookies = new Cookies();
const isHQAdmin = cookies.get('beccascookie') != undefined;

const useUserRole = () => {
  const [isUserBusinessOwner, setIsUserBusinessOwner] = useState(false);
  const { user, activeOfficeId, isAdmin, isSuperAdmin, isOfficeAdmin } =
    useActiveOffice();
  const { data: users = [] } = useGetSettingsUsers(activeOfficeId);

  useEffect(() => {
    const businessOwner = users?.find((user) => user.isOwner);

    setIsUserBusinessOwner(
      (user && user.data.userId === businessOwner?.userId) || false,
    );
  }, [users]);
  const testMode = window.location.href.indexOf(TENANT.prodUrl) == -1;

  return {
    isPlanCoordinator: user?.data.planCoordinator ?? false,
    isOwner: isUserBusinessOwner,
    isHQAdmin,
    isAdmin,
    isSuperAdmin,
    isOfficeAdmin,
    testMode,
  };
};

export default useUserRole;
