import { createEndpoint } from 'new/api/utils';
import { api } from 'new/api/axios';
import {
  ChangeLogoRequest,
  DisconnectPMSRequest,
  FullDentistInfoResponse,
  InitiatePMSConnectionRequest,
  PMSProviderPaymentResponse,
  PMSSupportedResponse,
  ProfileImageRequest,
  ProfileImageResponse,
  SetPMSProviderPaymentRequest,
  SetPermissionsRequest,
  SettingsUserResponse,
  ToggleDeletionRequest,
  ToggleDeletionResponse,
  ToggleNotifyUninsuredRequest,
  TogglePMSWriteRequest,
} from 'new/modules/OfficeSettings/types';
import { timeoutPromise } from 'new/utils/functions';

export const changeLogo = createEndpoint<unknown, ChangeLogoRequest>({
  url: '/users/:id/dentist-info/:id/logo/',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const deleteLogo = createEndpoint({
  url: '/users/:id/dentist-info/:id/logo/',
  endpoint: ({ url }) => api.delete(url),
});

export const profileImage = createEndpoint<unknown, ChangeLogoRequest>({
  url: '/users/:id/dentist-info/:id/set-avatar-no-auth/',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const deleteProfileImage = createEndpoint({
  url: '/users/:id/dentist-info/:id/set-avatar-no-auth/',
  endpoint: ({ url }) => api.delete(url),
});

export const officeImageEndpoint = ({
  id,
  fileURL,
  imageId,
}: ProfileImageRequest) =>
  api.post<ProfileImageResponse>(
    `/users/${id}/dentist-info/${id}/photos/${imageId}`,
    { fileURL },
  );

export const deleteOfficeImageEndpoint = ({
  id,
  imageId,
}: Omit<ProfileImageRequest, 'fileURL'>) =>
  api.delete(`/users/${id}/dentist-info/${id}/photos/${imageId}`);

export const getFullDentistInfo = createEndpoint<FullDentistInfoResponse>({
  url: '/users/:id/dentist-info/full',
  endpoint: ({ url }) => api.post(url),
});

export const getSettingsUsers = createEndpoint({
  url: '/dentists/:id/users',
  endpoint: ({ url }) => api.get<SettingsUserResponse>(url),
});

export const deactivateUser = createEndpoint<
  ToggleDeletionResponse,
  Omit<ToggleDeletionRequest, 'dentistInfoId'>
>({
  url: '/dentists/:id/users/reactivate',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const addUser = createEndpoint({
  url: '/dentists/:id/users/add',
  endpoint: ({ url, params }) =>
    api.post<{ userId: number; firstName: string; lastName: string }>(
      url,
      params,
    ),
});

export const deleteUser = createEndpoint({
  url: '/dentists/:id/users/delete',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const editUser = createEndpoint({
  url: '/dentists/:id/users/update?upgrade=true',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const updateMarketplaceInfo = createEndpoint({
  url: '/dentist-info/updateMarketplaceInfo/:id',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const updateOfficeProfile = createEndpoint({
  url: '/dentist-info/updateOfficeProfile/:id',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const setPermissions = createEndpoint<unknown, SetPermissionsRequest>({
  url: '/dentists/:id/users/changePermissions',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const initiatePMSConnectionEndpoint = createEndpoint<
  unknown,
  InitiatePMSConnectionRequest
>({
  url: '/dentists/initiatePMSConnection',
  endpoint: ({ url, params }) => {
    return Promise.all([api.post(url, params), timeoutPromise(3000)]).then(
      ([response]) => response,
    );
  },
});

export const disconnectPMSEndpoint = createEndpoint<
  unknown,
  DisconnectPMSRequest
>({
  url: '/dentists/disconnectPMSConnection',
  endpoint: ({ url, params }) => {
    return Promise.all([api.post(url, params), timeoutPromise(3000)]).then(
      ([response]) => response,
    );
  },
});

export const togglePMSWriteEndpoint = createEndpoint<
  unknown,
  TogglePMSWriteRequest
>({
  url: '/sikka-connect/toggleWriteAbilities',
  endpoint: ({ url, params }) => {
    return Promise.all([api.post(url, params), timeoutPromise(2000)]).then(
      ([response]) => response,
    );
  },
});

export const toggleNotifyUninsuredEndpoint = createEndpoint<
  unknown,
  ToggleNotifyUninsuredRequest
>({
  url: '/sikka-connect/toggleNotifyUninsured',
  endpoint: ({ url, params }) => {
    return Promise.all([api.post(url, params), timeoutPromise(2000)]).then(
      ([response]) => response,
    );
  },
});

export const getPMSSupported = createEndpoint({
  url: '/sikka-connect/getPMSSupported',
  endpoint: ({ url }) => api.get<PMSSupportedResponse>(url),
});

export const getPMSProviderPayment = createEndpoint({
  url: '/sikka-connect/getProviderPayment/:id',
  endpoint: ({ url }) => api.get<PMSProviderPaymentResponse>(url),
});

export const setPMSProviderPayment = createEndpoint<
  unknown,
  SetPMSProviderPaymentRequest
>({
  url: '/sikka-connect/setProviderPayment',
  endpoint: ({ url, params }) => {
    return Promise.all([api.post(url, params), timeoutPromise(2000)]).then(
      ([response]) => response,
    );
  },
});
