import { ThemeOptions } from '@mui/material';

const vhq: ThemeOptions = {
  palette: {
    primary: {
      main: '#00b7af',
      light: '#BEECEA',
      dark: '#407175',
      contrastText: '#fff',
    },
    secondary: {
      light: '#E2E2E2',
      main: '#9F9F9F',
      dark: '#707070',
    },
    error: {
      main: '#cd2c25',
    },
    warning: {
      light: '#ee9f3c',
      main: '#ee9f3c',
    },
    success: {
      light: '#00b7af',
      main: '#00b7af',
      dark: '#B5EBCF',
    },
    grey: {
      200: '#FAFAFA', // very light grey
      300: '#D1D2D4', // light grey
      400: '#7E8188', // medium grey
      600: '#6A6D75', // grey
      800: '#555962', // dark grey
    },
    blueGrey: {
      300: '#d2dfe5',
      400: '#c0d7e3',
      600: '#357083',
    },
    dhqBlack: { main: '#101820', light: '#40464D' },
    dhqBlue: {
      main: '#00b7af',
      dark: '#407175',
      light: '#555962',
    },
    dhqGray: { main: '#41464C' },
    dhqYellow: { main: '#ffde03', light: '#F7FA07' },
    dhqLighterBlue: { main: '#E1F1F7', dark: '#CAE9F5', light: '' },
    dhqGreen: {
      light: '#01b7af',
      main: '#316a7d',
    },
    login: {
      buttonBg: '#25AB90',
      color: '#616161',
    },
    misc: {
      background: '#f8f6f1',
      sidebarAdminBackground: '#332e2e',
      sidebarBackground: '#332e2e',
    },
    planStatus: {
      active: '#00b7af',
      inactive: '#d63a4a',
      archived: '#2c2c2c',
      draft: '#bfbfbf',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Rubik',
      fontWeight: 700,
      color: '#00b7af',
    },
    h2: {
      fontFamily: 'Rubik',
      fontWeight: 700,
      color: '#00b7af',
    },
    h3: {
      fontFamily: 'Rubik',
      fontWeight: 700,
      color: '#00b7af',
    },
    h4: {
      fontFamily: 'Roboto',
      color: '#00b7af',
    },
    body1: {
      fontFamily: 'Roboto',
    },
    body2: {
      fontFamily: 'Roboto',
    },
    button: {
      fontFamily: 'Roboto',
    },
  },
  spacing: 8,
};

export default vhq;
