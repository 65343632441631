import { createEndpoint } from 'new/api/utils';
import { api } from 'new/api/axios';
import {
  ConnectStripePlaidRequest,
  ConnectStripePlaidResponse,
  GetPlaidLinkTokenResponse,
  PMSMemberSearchRequest,
  PMSMemberSearchResponse,
  ReenrollRequest,
  SignupRequest,
  SignupResponse,
} from 'new/modules/InternalPatientSignup/types';
import { Id } from 'new/api/types';

export const signup = createEndpoint<SignupResponse, SignupRequest>({
  url: '/accounts/signup',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const checkExistingEmail = createEndpoint<
  { error?: string },
  { email: string }
>({
  url: '/accounts/check-email',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const reEnrollEndpoint = ({
  memberId,
  officeId,
  membershipId,
  ...data
}: ReenrollRequest) =>
  api.put<{ data: { clientId: number } }>(
    `/dentists/${officeId}/subscription/plan/${memberId}/re-enroll?membershipId=${membershipId}`,
    { ...data, membershipId },
  );

export const activateInOfficeMember = ({
  dentistInfoId,
  userId,
  membershipId,
}: {
  dentistInfoId: Id;
  userId: Id;
  membershipId?: Id;
}) =>
  api.post(
    `/dentists/${dentistInfoId}/subscription/members/${userId}/activate_cash`,
    { membershipId },
  );

export const getPlaidLinkTokenEndpoint =
  createEndpoint<GetPlaidLinkTokenResponse>({
    url: '/stripe-connect/createPlaidLinkToken',
    endpoint: ({ url }) => api.post<GetPlaidLinkTokenResponse>(url),
  });

export const connectStripePlaidEndpoint = createEndpoint<
  ConnectStripePlaidResponse,
  ConnectStripePlaidRequest
>({
  url: '/stripe-connect/plaidToken',
  endpoint: ({ url, params }) =>
    api.post<ConnectStripePlaidResponse>(url, params),
});

export const getPMSMembersBySearchTerm = createEndpoint<
  PMSMemberSearchResponse,
  PMSMemberSearchRequest
>({
  url: '/sikka-connect/matchNewMembers',
  endpoint: ({ url, params }) => api.post(url, params),
});
