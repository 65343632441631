import { Id } from 'new/api/types';
import { getFlattedPlans, getIsMonthlyPlan } from 'new/components/PlanSelect';
import useActiveOffice from 'new/hooks/useActiveOffice';
import useUserRole from 'new/hooks/useUserRole';
import { LocaleContext } from 'new/i18n/LocaleContext';
import { LogInviteIdRequest } from 'new/modules/Setup/types';
import {
  getOfficeDiscount,
  officeInfoNoAuth,
  sendInviteId,
} from 'new/modules/ExternalPatientSignup/endpoints';
import {
  ExternalPatientsStore,
  ExternalSignupCheckoutForm,
  SignupData,
} from 'new/modules/ExternalPatientSignup/types';
import {
  AddMember,
  AddMemberForm,
  PlanType,
  SignupCheckoutForm,
} from 'new/modules/InternalPatientSignup/types';
import { formatNumberAsCurrency } from 'new/utils/numbers';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import create from 'zustand';
import { useInternalPatientsStore } from '../InternalPatientSignup/hooks';
import { GPP_PERCENTAGE_PRICING } from '../ManagePlans/constants';
import { useGetPlans } from '../MembershipPlansManage/hooks';
import { getGroupDiscount, getGroupDiscounts } from './utils';

export const useExternalPatientsStore = create<ExternalPatientsStore>(
  (set) => ({
    officeId: '',
    setOfficeId: (officeId) => set({ officeId }),
    appliedGroupCode: undefined,
    planType: undefined,
    members: [] as AddMember[],
    setMembers: (members) => set({ members }),
    setAccountOwner: (accountOwner) =>
      set({ accountOwner: accountOwner || undefined }),
    setGroupCode: (appliedGroupCode) => set({ appliedGroupCode }),
    setPlanType: (planType: PlanType) => set({ planType }),
    resetPatientStore: () =>
      set({
        members: [],
        officeId: '',
        accountOwner: undefined,
        appliedGroupCode: undefined,
      }),
  }),
);

function isInternalAccountOwner(
  accountOwner?: SignupCheckoutForm | ExternalSignupCheckoutForm,
): accountOwner is SignupCheckoutForm {
  return (
    !!accountOwner &&
    !!Object.prototype.hasOwnProperty.call(accountOwner, 'additionalMembers')
  );
}

const usePatientSignupStore = (type: 'internal' | 'external') => {
  const externalStore = useExternalPatientsStore();
  const internalStore = useInternalPatientsStore();
  if (type === 'internal') return internalStore;
  return externalStore;
};

export const useOfficeInfoNoAuth = (
  officeId?: Id,
  smBusinessInfoId?: string,
  token?: string,
) =>
  useQuery({
    queryKey: ['office query no auth', officeId],
    queryFn: () =>
      officeInfoNoAuth
        .endpoint({ smBusinessInfoId, token }, officeId)
        .then((res) => res.data),
    enabled: !!officeId,
  });

const useGetOfficeDiscount = (dentistInfoId?: string) => {
  return useQuery({
    queryKey: ['OFFICE_DISCOUNT', dentistInfoId],
    queryFn: () =>
      getOfficeDiscount
        .endpoint({
          dentistInfoId: Number(dentistInfoId),
          smBusinessInfoId: null,
        })
        .then((res) => res.data),
    enabled: !!dentistInfoId,
  });
};

export const useSendInviteId = () =>
  useMutation<unknown, unknown, LogInviteIdRequest>((params) =>
    sendInviteId.endpoint(params),
  );

export const useSignupData = (
  type: 'internal' | 'external',
  gppNeedsAccountOwnerAddress = false,
  accountOwnerAddress?: string | null,
  existingMemberCount = 0,
): SignupData => {
  const {
    members,
    setMembers,
    setGroupCode,
    officeId: storeOfficeId,
    appliedGroupCode,
    accountOwner,
    planType: selectedPlanType,
  } = usePatientSignupStore(type);
  const { activeOfficeId, legacyPricing } = useActiveOffice();
  const officeId = type == 'internal' ? activeOfficeId : storeOfficeId;
  const { data: officeDiscount, isLoading: isLoadingDiscounts } =
    useGetOfficeDiscount(officeId);
  const { data: allPlans, isLoading: isLoadingPlans } = useGetPlans(officeId);
  const plans = getFlattedPlans(allPlans?.custom_memberships);
  const { isSuperAdmin } = useUserRole();
  const { locale } = useContext(LocaleContext);
  const isSpanish = locale === 'es-US';

  const { data: officeInfo, isLoading: isLoadingOffice } =
    useOfficeInfoNoAuth(officeId);
  const gpp = officeInfo?.data.dentistInfo.gpp;
  const isGppActive = useMemo(
    () =>
      gpp?.active &&
      gpp.approvalDate &&
      ((gppNeedsAccountOwnerAddress && !!accountOwnerAddress) ||
        !gppNeedsAccountOwnerAddress),
    [JSON.stringify(gpp), gppNeedsAccountOwnerAddress, accountOwnerAddress],
  );
  const groupDiscountsData = getGroupDiscounts(officeInfo?.data);

  const totalMembersCount = useMemo(() => {
    let numberOfMembers = members.length + existingMemberCount;
    if (
      isInternalAccountOwner(accountOwner) &&
      accountOwner?.additionalMembers &&
      accountOwner?.additionalMembers.length > 0
    ) {
      if (
        accountOwner?.status &&
        ['active', 'active_transferred', 'active_cash', 'past_due'].indexOf(
          accountOwner?.status,
        ) != -1
      )
        numberOfMembers++;
      accountOwner?.additionalMembers?.map((additionalMember) => {
        if (
          additionalMember.statuses.some((status) =>
            ['active', 'active_transferred', 'active_cash'].includes(status),
          )
        )
          numberOfMembers++;
      });
    }
    return numberOfMembers;
  }, [members]);

  const groupDiscountPercent = useMemo(
    () =>
      getGroupDiscount({
        groupDiscounts: groupDiscountsData,
        numberOfMembers: totalMembersCount,
      }),
    [JSON.stringify(groupDiscountsData), totalMembersCount],
  );

  const codeDiscountPercent = appliedGroupCode?.discountByDentist ?? 0;

  const setMembersFromForm = useCallback(
    (formMembers: AddMemberForm[], checkPlanType = false) => {
      setMembers(
        formMembers.map((member) => {
          const {
            name,
            spanishName,
            price,
            type: planType,
            insured,
            uninsured,
            disableGpp,
            activationFee: patientActivationFee,
            disableFamilyDiscounts,
          } = plans.find((item) => item.id === Number(member.plan)) || {};
          const numericPrice = price ? Number(price) : 0;
          const isMonthly = getIsMonthlyPlan(planType);
          const perPlanGroupDiscountPercent = disableFamilyDiscounts
            ? 0
            : groupDiscountPercent;
          const isGroupDiscount =
            perPlanGroupDiscountPercent > codeDiscountPercent;
          const discountPercent =
            perPlanGroupDiscountPercent > codeDiscountPercent
              ? perPlanGroupDiscountPercent
              : codeDiscountPercent;
          const discountedPrice =
            numericPrice - (numericPrice * discountPercent) / 100;
          const gppActivationFee =
            discountedPrice * 12 * (GPP_PERCENTAGE_PRICING / 100);
          let hasCorrectPlanType = true;
          if (checkPlanType)
            hasCorrectPlanType =
              (selectedPlanType == 'insured' && !!insured) ||
              (selectedPlanType == 'uninsured' && !!uninsured);

          const isPlanGppActive = !!isGppActive && !disableGpp;
          const activationFee = patientActivationFee || 0;
          return {
            ...member,
            plan: hasCorrectPlanType ? member.plan : '',
            isEdit: hasCorrectPlanType ? member.isEdit : true,
            price: {
              value: numericPrice,
              currency: formatNumberAsCurrency(numericPrice, 2, true),
            },
            discountedPrice: {
              value: discountedPrice,
              currency: formatNumberAsCurrency(discountedPrice, 2, true),
              isGroupDiscount,
              percent: discountPercent,
            },
            activationFee: {
              value: activationFee,
              currency: formatNumberAsCurrency(activationFee, 2, true),
            },
            setupFee: {
              value: gppActivationFee,
              currency: formatNumberAsCurrency(gppActivationFee, 2, true),
            },
            allowPayorSelection:
              !legacyPricing &&
              type === 'internal' &&
              isMonthly &&
              isPlanGppActive &&
              ((isSuperAdmin && gpp?.gppAdminOnlyWaive == true) ||
                gpp?.gppAdminOnlyWaive == false),
            allowWaiveFee:
              type === 'internal' &&
              isMonthly &&
              !isPlanGppActive &&
              !!activationFee,
            activeFee:
              isMonthly && isPlanGppActive && member.payor === 'member'
                ? 'setup'
                : isMonthly && !isPlanGppActive && !!activationFee
                ? 'activation'
                : null,
            isMonthly,
            planName: (isSpanish && spanishName ? spanishName : name) || '',
            isPlanGppActive,
          };
        }),
      );
    },
    [
      groupDiscountPercent,
      codeDiscountPercent,
      JSON.stringify(plans),
      isGppActive,
      isSpanish,
      selectedPlanType,
    ],
  );
  const membersForForm = useMemo(
    () =>
      members.map((member) => ({
        firstName: member.firstName,
        lastName: member.lastName,
        dob: member.dob,
        plan: member.plan,
        waiveFee: member.waiveFee,
        payor: member.payor,
        isEdit: member.isEdit,
        chartId: member.chartId,
        id: member.id,
      })),
    [JSON.stringify(members)],
  );
  useEffect(() => {
    setMembersFromForm(membersForForm);
  }, [setMembersFromForm, JSON.stringify(membersForForm)]);

  const totalAmount = useMemo(
    () =>
      members.reduce((total, member) => {
        const price =
          member.price.value > member.discountedPrice.value
            ? member.discountedPrice.value
            : member.price.value;
        const fee =
          member.activeFee === 'activation' && !member.waiveFee
            ? member.activationFee.value
            : member.activeFee === 'setup'
            ? member.setupFee.value
            : 0;
        return total + price + fee;
      }, 0),
    [JSON.stringify(members)],
  );
  const totalCommitment = useMemo(
    () =>
      members.reduce((total, member) => {
        const price =
          (member.price.value > member.discountedPrice.value
            ? member.discountedPrice.value
            : member.price.value) * (member.isMonthly ? 12 : 1);
        const fee =
          member.activeFee === 'activation' && !member.waiveFee
            ? member.activationFee.value
            : member.activeFee === 'setup'
            ? member.setupFee.value
            : 0;
        return total + price + fee;
      }, 0),
    [JSON.stringify(members)],
  );
  return {
    totalAmount: {
      value: totalAmount,
      currency: formatNumberAsCurrency(totalAmount, 2, true),
    },
    totalCommitment: {
      value: totalCommitment,
      currency: formatNumberAsCurrency(totalCommitment, 2, true),
    },
    members,
    codeDiscounts: officeDiscount?.discounts ?? [],
    setMembersFromForm,
    setMembers,
    membersForForm,
    gpp,
    setGroupCode,
    isLoading: isLoadingDiscounts || isLoadingPlans || isLoadingOffice,
    totalMembersCount,
    codeDiscountPercent,
  };
};
