import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import moment from 'moment-timezone';
import { COMMON_DATE_FORMAT_DAYJS } from 'new/constants/date';

type GetEtTimezoneDateConfig = {
  isDateOnly?: boolean;
  isTimeOnly?: boolean;
  format?: string;
};

export const formatDate = (
  dateValue?: string | number | Date,
  config?: GetEtTimezoneDateConfig,
) => {
  const { isDateOnly, isTimeOnly, format: dateFormat } = config || {};
  let date;

  if (typeof dateValue === 'string' && dateValue.length) {
    date = new Date(dateValue);
  } else if (typeof dateValue === 'number') {
    date = dayjs.unix(dateValue).toDate();
  } else if (
    dateValue instanceof Date &&
    Object.prototype.toString.call(dateValue) === '[object Date]'
  ) {
    date = dateValue;
  } else {
    return '';
  }

  if (dateFormat?.length) {
    return dayjs(date).format(dateFormat);
  } else if (isDateOnly) {
    return dayjs(date).format(COMMON_DATE_FORMAT_DAYJS);
  } else if (isTimeOnly) {
    return dayjs(date).format('hh:mm a');
  } else {
    return dayjs(date).format(`${COMMON_DATE_FORMAT_DAYJS} hh:mm a`);
  }
};

const secondsIn1hour = 3600;
const secondsIn24hours = secondsIn1hour * 24;
const secondsIn30days = secondsIn24hours * 30;

export const calculateAge = (dob: string) => {
  const date = new Date(dob);

  return dayjs().diff(date, 'year');
};

export const dateIsValid = (date: unknown) =>
  date instanceof Date && !isNaN(date as unknown as number);

export const isInLessThan30days = (date: string) => {
  if (!date) {
    return false;
  }

  const difference = dayjs(date).diff(dayjs(), 'second');

  return difference > -86400 && difference <= secondsIn30days;
};

export const getLocalDateTime = (
  dateWithTime: string | number | Date,
  config?: GetEtTimezoneDateConfig,
) => {
  // const timezone = moment.tz.guess();
  moment.tz.setDefault('Europe/London');

  const dateFormat = config?.isTimeOnly
    ? ''
    : config?.format ?? COMMON_DATE_FORMAT_DAYJS;
  const timeFormat = config?.isDateOnly ? '' : 'HH:mm';

  let timezoneDateOnly = '';
  let timezoneTimeOnly = '';

  if (config?.isTimeOnly) {
    timezoneTimeOnly = dayjs
      .utc(dateWithTime)
      .tz('Europe/London')
      .format(timeFormat);
    const hour = parseInt(timezoneTimeOnly.split(':')[0]);
    timezoneTimeOnly += hour > 11 ? 'pm' : 'am';

    if (timezoneTimeOnly[0] === '0') {
      timezoneTimeOnly = timezoneTimeOnly.slice(1);
    }
  }

  if (config?.isDateOnly) {
    timezoneDateOnly = dayjs
      .utc(dateWithTime)
      .tz('Europe/London')
      .format(dateFormat);
  }

  return `${timezoneDateOnly} ${timezoneTimeOnly}`;
};
