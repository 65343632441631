import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  changeLogo,
  getFullDentistInfo,
  profileImage,
  getSettingsUsers,
  deleteLogo,
  deleteProfileImage,
  deactivateUser,
  editUser,
  updateOfficeProfile,
  deleteUser,
  setPermissions,
  addUser,
  initiatePMSConnectionEndpoint,
  disconnectPMSEndpoint,
  togglePMSWriteEndpoint,
  getPMSSupported,
  getPMSProviderPayment,
  setPMSProviderPayment,
  toggleNotifyUninsuredEndpoint,
} from 'new/modules/OfficeSettings/endpoints';
import {
  DeleteUserRequest,
  EditUserRequest,
  ChangeLogoRequest,
  OfficeProfileRequest,
  ToggleDeletionRequest,
  ToggleDeletionResponse,
  SetPermissionsRequest,
  AddUserRequest,
  InitiatePMSConnectionRequest,
  DisconnectPMSRequest,
  TogglePMSWriteRequest,
  SetPMSProviderPaymentRequest,
  ToggleNotifyUninsuredRequest,
} from 'new/modules/OfficeSettings/types';
import { Id } from 'new/api/types';

export const useChangeLogo = (id?: string) =>
  useMutation<unknown, unknown, ChangeLogoRequest>(
    (params) => changeLogo.endpoint(params, id),
    {
      onSuccess: () => {
        toast.success('Your Logo was added successfully');
      },
    },
  );

export const useDeleteLogo = (id?: string) =>
  useMutation(() => deleteLogo.endpoint({}, id), {
    onSuccess: () => {
      toast.success('Your Logo was deleted successfully');
    },
  });

export const useSetProfileImage = (id?: string) =>
  useMutation<unknown, unknown, ChangeLogoRequest>(
    (params) => profileImage.endpoint(params, id),
    {
      onSuccess: () => {
        toast.success('Your Profile Image was added successfully');
      },
    },
  );

export const useDeleteProfileImage = (id?: string) =>
  useMutation(() => deleteProfileImage.endpoint({}, id), {
    onSuccess: () => {
      toast.success('Your Profile Image was deleted successfully');
    },
  });

// export const useSetOfficeImage = () =>
//   useMutation<ProfileImageResponse, unknown, ProfileImageRequest>(
//     (params) => officeImageEndpoint(params).then((data) => data.data),
//     {
//       onSuccess: () => {
//         toast.success('Your Office Image was added successfully');
//       },
//     },
//   );

// export const useDeleteOfficeImage = (id: string) =>
//   useMutation<unknown, unknown, Omit<ProfileImageRequest, 'fileURL' | 'id'>>(
//     (params) => deleteOfficeImageEndpoint({ id, ...params }),
//     {
//       onSuccess: () => {
//         toast.success('Your Office Image was deleted successfully');
//       },
//     },
//   );

export const useGetFullDentistInfo = (id?: string) =>
  useQuery({
    queryKey: `FULL DENTIST_INFO: ${id}`,
    queryFn: () => getFullDentistInfo.endpoint({}, id).then((res) => res.data),
    enabled: !!id,
  });

export const useGetSettingsUsers = (id?: string) =>
  useQuery({
    queryKey: ['USERS', id],
    queryFn: () =>
      getSettingsUsers.endpoint({}, id).then((res) => res.data.data),
    enabled: !!id,
  });

export const useDeactivateUser = () =>
  useMutation<ToggleDeletionResponse, unknown, ToggleDeletionRequest>(
    ({ dentistInfoId, ...params }) =>
      deactivateUser.endpoint(params, dentistInfoId).then((res) => res.data),
    {
      onSuccess: (data, params) => {
        if (data.error) {
          toast.error(data.error.errors, { toastId: data.error.errors });
        } else {
          toast.success(
            `User account is ${!params.isDeleted ? 'de' : ''}activated`,
          );
        }
      },
    },
  );

export const useAddUser = (locationId?: Id) =>
  useMutation<
    { userId: number; firstName: string; lastName: string },
    unknown,
    AddUserRequest
  >((params) => addUser.endpoint(params, locationId).then((res) => res.data), {
    onSuccess: () => {
      toast.success('User added');
    },
  });

export const useDeleteUser = () =>
  useMutation<unknown, unknown, DeleteUserRequest>(
    ({ dentistInfoId, userId }) =>
      deleteUser.endpoint({ userId }, dentistInfoId),
  );

export const useEditUser = () =>
  useMutation<unknown, unknown, EditUserRequest>((params) =>
    editUser.endpoint(params, params.dentistInfoId),
  );

// export const useUpdateMarketplaceInfo = (locationId?: Id) =>
//   useMutation<unknown, unknown, MarketplaceInfoRequest>((params) =>
//     updateMarketplaceInfo.endpoint(params, locationId),
//   );

export const useUpdateOfficeProfile = (locationId?: Id) =>
  useMutation<unknown, unknown, OfficeProfileRequest>((params) =>
    updateOfficeProfile.endpoint(params, locationId),
  );

export const useSavePermissions = (locationId?: Id) =>
  useMutation<unknown, unknown, SetPermissionsRequest>(
    (params) => setPermissions.endpoint(params, locationId),
    {
      onSuccess: () => {
        toast.success('Permissions changed');
      },
    },
  );

export const useInitiatePMSConnection = () =>
  useMutation((params: InitiatePMSConnectionRequest) =>
    initiatePMSConnectionEndpoint.endpoint(params),
  );

export const useDisconnectPMS = () =>
  useMutation((params: DisconnectPMSRequest) =>
    disconnectPMSEndpoint.endpoint(params),
  );

export const useTogglePMSWrite = () =>
  useMutation((params: TogglePMSWriteRequest) =>
    togglePMSWriteEndpoint.endpoint(params),
  );

export const useToggleNotifyUninsured = () =>
  useMutation((params: ToggleNotifyUninsuredRequest) =>
    toggleNotifyUninsuredEndpoint.endpoint(params),
  );

export const useGetPMSSupported = () =>
  useQuery({
    queryKey: ['PMS_SUPPORTED'],
    queryFn: () => getPMSSupported.endpoint().then((res) => res.data),
  });

export const useGetPMSProviderPayment = (dentistInfoId: number) =>
  useQuery({
    queryKey: ['PMS_PROVIDER_PAYMENT'],
    queryFn: () =>
      getPMSProviderPayment.endpoint({}, dentistInfoId).then((res) => res.data),
  });

export const useSetPMSProviderPayment = () =>
  useMutation((params: SetPMSProviderPaymentRequest) =>
    setPMSProviderPayment.endpoint(params),
  );
